export const NUMERAL_FR_SPEC = {
	delimiters: {
		thousands: ' ',
		decimal: ',',
	},
	abbreviations: {
		thousand: 'k',
		million: 'm',
		billion: 'b',
		trillion: 't',
	},
	ordinal: function (number) {
		return number === 1 ? 'er' : 'ème';
	},
	currency: {
		symbol: 'DHs',
	},
};
