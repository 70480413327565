import { TableColumn } from '../model';

export class ArrayUtils {
	static updateItemInArray(item: any, array: any[]): void {
		if (array.includes(item)) array.splice(array.indexOf(item), 1);
		else array.push(item);
	}
}

export function reorderTableColumns(
	tableColumns: TableColumn[],
	startIndex: number,
	targetIndex: number
): TableColumn[] {
	// Validate indices
	if (
		startIndex < 0 ||
		startIndex >= tableColumns.length ||
		targetIndex < 0 ||
		targetIndex >= tableColumns.length
	) {
		console.error('Invalid start or target index.');
		return;
	}

	// Remove the column from the original position
	const [removedColumn] = tableColumns.splice(startIndex, 1);

	// Insert the column at the new position
	tableColumns.splice(targetIndex, 0, removedColumn);

	// Update the order attribute of all columns based on their new positions
	tableColumns.forEach((column, index) => (column.order = index));

	// Return the updated tableColumns array
	return tableColumns;
}
