// Colors constants
const EXAM_STATUS_COLORS: any = {
	EXAM_STARTED: '#00bcd4',
	EXAM_FINISHED: '#089219',
	EXAM_MISSED: '#ff0000',
	WAITING: '#FF9800',
	LATE: '#ff0000',
	BEFORE_TIME: '#ff00bc',
};

const REPORT_ICONS_COLORS: any = {
	WAITING: '#999',
	IN_PROGRESS: '#1aa4c4',
	FINISHED: '#16a3f5',
	VERIFIED: '#ca1151',
	TO_REVIEW: '#ff0000',
	TO_SIGN: '#ffb364',
	SIGNED: '#169134',
	DELIVERED: '#917384',
};

const REPORT_STATUS_COLORS: any = {
	WAITING: '#999',
	INPROGRESS: '#1aa4c4',
	READY: '#1bca68',
	DELIVERED: '#bd386d',
};

const REPORT_STATUSES_ICONS: any = {
	WAITING: 'mdi-file-outline',
	INPROGRESS: 'mdi-file-clock',
	READY: 'mdi-file-check',
	DELIVERED: 'mdi-file-lock',
};

const REPORTING_STATUS_ICONS: any = {
	WAITING: 'mdi-file-outline',
	IN_PROGRESS: 'mdi-file-clock',
	FINISHED: 'mdi-file',
	TO_REVIEW: 'mdi-file-alert',
	TO_SIGN: 'mdi-file-edit',
	SIGNED: 'mdi-file-check',
	DELIVERED: 'mdi-file-lock',
	VERIFIED: 'mdi-file-certificate',
};

const EXAM_STATUS_ICONS: any = {
	EXAM_STARTED: 'mdi-timelapse',
	EXAM_FINISHED: 'mdi-check-all',
	WAITING: 'mdi-emoticon-neutral',
	LATE: 'mdi-account-alert',
	BEFORE_TIME: 'mdi-progress-clock',
	EXAM_MISSED: 'mdi-call-missed',
};

// Helpers functions for status colors and icons;
export function getPatientStatusIcon(status: string): string {
	return EXAM_STATUS_ICONS[status] ?? 'mdi-timer-sand';
}

export function getReportingStatusIcon(status: string): string {
	return REPORTING_STATUS_ICONS[status] ?? 'mdi-file-outline';
}

export function getReportStatusIcon(status: string): string {
	return REPORT_STATUSES_ICONS[status] ?? 'mdi-file-cancel-outline';
}

export function getIconColor(status: string): string {
	return EXAM_STATUS_COLORS[status] ?? '#999';
}

export function getReportIconColor(status: string): string {
	return REPORT_ICONS_COLORS[status] ?? '#999';
}

export function getCompetedReportIconColor(status: string): string {
	return REPORT_STATUS_COLORS[status] ?? '#999';
}
