import FileSaver from 'file-saver';

export function loadJSScript(scriptSrc: string, tagId: string) {
	const node = document.createElement('script');
	node.src = scriptSrc;
	node.type = 'text/javascript';
	node.id = tagId;
	document.getElementsByTagName('body')[0].appendChild(node);
}

export function loadRLEScripts() {
	if (!document.getElementById('rle-int'))
		loadJSScript('/LPW/js/LPW.REInt.js', 'rle-int');
	if (!document.getElementById('rle-api'))
		loadJSScript(
			'/LPW/api/a/AppSettings/getAppGlobalSettings?responseType=jsonp&func=LPW.REInt.setSettings',
			'rle-api'
		);
}

export function getPrintFrame(): HTMLIFrameElement {
	let frame = document.getElementById('PRINT_FRAME_ID');

	if (frame) frame.parentElement.removeChild(frame);

	frame = document.createElement('iframe');
	frame.style.position = 'absolute';
	frame.style.top = '-9999px';
	frame.setAttribute('id', 'PRINT_FRAME_ID');

	document.body.appendChild(frame);

	return frame as HTMLIFrameElement;
}

export function printBlob(blob: Blob | MediaSource | any) {
	const frame = getPrintFrame();
	const objectUrl = URL.createObjectURL(blob);

	frame.setAttribute('src', objectUrl);

	const load = () => {
		frame.contentWindow.print();
		window.addEventListener('focus', afterPrint);
	};

	const afterPrint = () => {
		if (frame.parentElement) frame.parentElement.removeChild(frame);
		window.removeEventListener('focus', afterPrint);
	};

	frame.addEventListener('load', load);
}

export function openBlobInBrowser(blob: Blob | MediaSource | any) {
	const blobUrl = URL.createObjectURL(blob);

	// Create an iframe to display the PDF
	const iframe = document.createElement('iframe');
	iframe.src = blobUrl;
	iframe.style.width = '900px';
	iframe.style.height = 'calc(100% - 10px)'; // Adjust the height as needed
	iframe.style.position = 'fixed';
	iframe.style.zIndex = '9999';
	iframe.style.top = '4px';
	iframe.style.left = '20%';
	iframe.style.boxShadow = '0 2px 8px grey';
	iframe.style.backgroundColor = 'black';

	document.body.appendChild(iframe);

	// Button to close the iframe after preview
	const button = document.createElement('button');
	button.style.position = 'fixed';
	button.style.top = '15px';
	button.style.left = 'calc(20% + 8px)';
	button.style.zIndex = '9999';
	button.style.height = '36px';
	button.style.padding = '8px';
	button.style.fontWeight = 'bold';
	button.style.backgroundColor = '#a2acb0';
	button.style.border = 'none';
	button.style.borderRadius = '6px';
	button.innerText = 'Close';

	button.addEventListener('click', e => {
		document.body.removeChild(iframe);
		document.body.removeChild(button);
	});
	document.body.appendChild(button);
}

export function downloadFile(
	blob: BlobPart,
	type = 'pdf',
	filename: string = `file_${new Date().getTime()}.${type}`
) {
	const blobFile = new Blob([blob], { type: `application/${type}` });
	const file = new File([blobFile], filename, {
		type: `application/${type}`,
	});

	FileSaver.saveAs(file);
}
